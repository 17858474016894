import { BootstrapVue } from 'bootstrap-vue'
import './set-public-path'
import Vue from 'vue';
import App from './App.vue';
import singleSpaVue from 'single-spa-vue';
import { store, } from "@isc/styleguide";

Vue.config.productionTip = false;
Vue.use(BootstrapVue)
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    el: "#top-navbar",
    store
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
