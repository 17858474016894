(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@isc/styleguide"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["@isc/styleguide", "vue"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@isc/styleguide"), require("vue")) : factory(root["@isc/styleguide"], root["vue"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__isc_styleguide__, __WEBPACK_EXTERNAL_MODULE_vue__) {
return 