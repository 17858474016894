<template>
  <header-comp />
</template>

<script>
import HeaderComp from "./components/Header.vue";
export default {
  name: "Navigation",
  components: {
    HeaderComp,
  },
};
</script>
